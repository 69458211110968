import * as React from "react"
import { motion, AnimatePresence } from "framer-motion";

import Layout from "../components/layout"
import Warehouses from "../components/warehouses"
import { Link } from "gatsby"

import documentos from "../images/documentos.svg"
import paquetes from "../images/paquetes.svg"
import courier from "../images/impo-courier.png"
import cargaExpandedImg from "../images/logistica-carga-expanded.png"
import estrella from "../images/estrella-bca.svg"
import cobertura from "../pdf/IMPO-COBERTURA-GEOGRAFICA-Y-TIEMPOS-DE-TRANSITO-2022.pdf"

const ImportacionPage = () => {
  const [courierExpand, setCourierExpand] = React.useState(false)
  const [limitsExpand, setLimitsExpand] = React.useState(false)
  const [cargaExpand, setCargaExpand ] = React.useState(false)

  return (
  <Layout
	seccion="importacion"	
	title="Importación"	
	linkTo="courier"
	>		
		<div id="courier" className="raicoContainer mt-30">
			<div className="row">
				<div id="expCol" className="col-12 col-sm-6">
					<div className={"expTxt bgRed "+(courierExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-12 col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
								
								<h2>Servicio Courier<br/>
								Internacional</h2></div>		
								
								{/*<p className="text-white">Servicio de Transporte Aéreo internacional puerta a puerta<br/> de cualquier parte del mundo de documentos y paquetes<br/> hasta 50kg de peso bruto y hasta Usd 3000 de valor fob.</p> */}

								<p
								style={{ marginRight: '30px' }}
								className="text-white">Servicio de transporte aéreo internacional puerta a puerta de
								documentos y paquetes hasta 50 kg. y USD 1000 de valor FOB.</p>
								
								<div id="expandCourier" className={"expand text-white "+(courierExpand ? 'invisible' : '')}
									onClick={() => setCourierExpand(!courierExpand)}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
									CONOCER MÁS
								</div>
							</div>
					</div>
				</div>
				
					<div className={"col-sm-6 expImg courier"} 
								  data-aos="zoom-in"								  
								  data-aos-duration="900">
						<div className={courierExpand ? 'expanded' : ''}></div>
					</div>
			</div>
			<AnimatePresence initial={false}>
				{courierExpand && (
				  <motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
					  open: { opacity: 1, height: "auto" },
					  collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.5 }}
				  >
						<div className="row">
							<div className="col-12 col-sm-6 serviceExpand pr-0 " style={{minHeight: '200px'}}>
								<div className="bgRed d-flex flex-column">
									<div className="leftExpand">
										<div className="courierTitle d-flex align-items-start">
											<img src={paquetes} />
											<h2 className="text-white">Servicio Courier <br/>Internacional Documentos</h2>
										</div>
										
										<p>Servicio de transporte aéreo internacional de documentos puerta a puerta.
										
										{/*<p>Es un servicio de importación vía aérea puerta a puerta, desarrollado para envíos de documentos.  */}
										
										<br/>
										
										Contamos con un servicio de recolección fácil, rápido y económico en cualquier parte del mundo y el mejor tiempo de tránsito.
										</p>
										
										<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
										
										<p>Si desea contratar o recibir más información sobre nuestro servicio:</p>
										<p className="requisitos"><a href="mailTo:impo@raiconet.com" target="_blank">CONSULTE AQUÍ</a></p>
										
										
										{/*
										<p>Para realizar una cotización envíe un mail a<br/>
										impo@raiconet.com con los siguientes datos:
										</p>
										
										<ul>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
											Origen.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Peso (Kg).</li>
											<li><svg
											style={{
												verticalAlign: 'top',
												marginTop: '4px'
											}}										
											xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
											<span className="d-inline-block">Medidas (cm) o Peso Volumétrico<br/>
											(Largo x alto x ancho/5000).</span></li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Valor FOB (USD).</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Descripción del contenido.</li>
										</ul>
										*/}
									</div>
									<div style={{marginTop: 'auto'}}><img src={courier} className="imgExpandLeft"  /></div>
								</div>
							</div>
							<div className="col-12 col-sm-6 serviceExpand pl-0">
								<div className="bgRed">
									<div className="courierTitle d-flex align-items-start">
										<img src={documentos} />
										<h2 className="text-white">Servicio Courier <br/>Internacional Paquetes</h2>
									</div>
									<p>Servicio de transporte aéreo internacional puerta a puerta de
paquetes hasta 50 kg. y USD 1000 de valor FOB. </p>

<p>Importantes acuerdos internacionales nos permiten brindarle el servicio de recolección de sus envíos de manera fácil, rápida y económica en cualquier parte del mundo.</p>
									
									{/* <p>Es un servicio de importación vía aérea
									puerta a puerta, diseñado para envíos urgentes que
									no superan los 50 kilos ni los USD 3.000 de valor FOB. </p>

									{/*<p>NO ES NECESARIO ESTAR INSCRIPTO COMO IMPORTADOR.</p>

									*/}
									
									{/*<p>Máxima calidad y servicio con menos tiempo de tránsito.
									Coordinamos la recolección inmediata.
									Contamos con vuelos diarios y directos desde Miami,
									Madrid y principales aeropuertos de Oriente.</p>
									
									
									*/}
									

									<p>También puede indicar a su proveedor habitual que le haga llegar su orden de compra a nuestros depósitos habilitados en Miami, Houston, Madrid y Shenzhen a través de su propio convenio doméstico.</p>
									
									<p>Todos ellos habilitados para la recepción de carga y realización de manejos especiales, como ser:</p>
									
									<ul
									style={{marginBottom: '20px'}}>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Re-empaque</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Toma de contenido</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Consolidación</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Control de documentación</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Fraccionamientos para su posterior despacho</li>
									</ul>
									
																		
									<p>La mercadería debe ser nueva, sin uso y no estar
									alcanzada por intervención de terceros organismos
									(Certificado de Origen, Estampilla, Seguridad Eléctrica,
									INAL, SENASA, ANMAT, CHAS, etc.)</p>
									
									<p className="requisitos">ÚNICOS REQUISITOS:<br/>
									 SER PERSONA FÍSICA O JURÍDICA CON CLAVE FISCAL NIVEL 3.<br/>
									 NO ES NECESARIO TENER LICENCIA DE IMPORTADOR.</p>
									
									<p>Para realizar una cotización envíe un mail a<br/>
									impo@raiconet.com con los siguientes datos:
									</p>
									
									<ul>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Origen.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Peso (Kg).</li>
										<li><svg
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}}										
										xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										<span className="d-sm-inline-block">Medidas (cm) o Peso Volumétrico <br className="d-none d-sm-block"/>
										(Largo x alto x ancho/5000).</span></li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Valor FOB (USD).</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Descripción del contenido.</li>
									</ul>
									
									<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
									
									<p>Si desea contratar o recibir más información sobre nuestro servicio:</p>
									<p className="requisitos"
									style={{ marginBottom: '40px' }}><a href="mailTo:impo@raiconet.com" target="_blank">CONSULTE AQUÍ</a></p>
									
									
								</div>
							</div>
						</div>
				  </motion.section>
				)}
			</AnimatePresence>
		</div>
		<div id="noLimits" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-12 col-sm-6 pr-0">
					<div className={"expTxt bgRed "+(limitsExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-12 col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
									<h2>Servicio Courier Carga</h2>
								</div>
								
								{/*<p className="text-white"
style={{paddingRight: '0'}}>Servicio de transporte puerta-aeropuerto de cargas aéreas <br/>livianas urgentes, que por sus características de peso,<br/> valor, o tipo de producto, quedan excluidas del<br/> régimen Courier.</p> */}
								
								<p className="text-white"
								style={{paddingRight: '0'}}>Servicio de transporte puerta-aeropuerto de cargas aéreas livianas urgentes que, por sus características de peso, valor, o tipo de producto, quedan excluidas del régimen courier.</p>
								
								{/*!limitsExpand && 
								<p className="text-white">Evitamos los costos fijos de manejo de<br/>
								documentación en origen y destino.</p>
								*/}
								
								{!limitsExpand && 
								<div className={"expand text-white "+(limitsExpand ? 'invisible' : '')}
									onClick={() => setLimitsExpand(!limitsExpand)}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
									CONOCER MÁS
								</div>
								}
								
								<AnimatePresence initial={false}>
									{limitsExpand && (
									  <motion.section
										key="content"
										initial="collapsed"
										animate="open"
										exit="collapsed"
										variants={{
										  open: { opacity: 1, height: "auto" },
										  collapsed: { opacity: 0, height: 0 }
										}}
										transition={{ duration: 1.1, ease: [0.04, 0.62, 0.23, 0.98] }}
									  >
									  <div className="infoExpanded">
									  <p>Este servicio  tiene el tratamiento operativo de un envío courier hasta el Aeropuerto Internacional de Ezeiza, donde se desconsolidará a bodega de carga general, para poder proceder a su liberación a través de un despacho formal.</p>

									  <p>La fortaleza es obtener la velocidad del courier y evitar los costos fijos de una carga aérea convencional, tales como, corte de guía , manejo de documentación o fletes mínimos.</p>
									  
									  <ul className="beneficios"
									  style={{marginTop: '30px',
									  marginBottom: '20px' }}
									  >
										<li><img src={estrella} alt="" title="" />Adelantamos al despachante toda la información del embarque para evitar costos extras de almacenaje.</li>
										<li><img 
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}} src={estrella} alt="" title="" /><span className="d-sm-inline-block">Consulta nuestros precios para acarreos de Ezeiza <br/>hasta su domicilio.</span></li>
										<li><img src={estrella} alt="" title="" />Realizamos el desconsolidado expréss, y entregamos la documentación original, el mismo día del arribo del vuelo.</li>
									  </ul>
									  
									  <p>Nuestro customer service le informará el<br/>
status de su carga antes y durante el trayecto.</p>

									  <p>¡Confianza exclusiva!</p>
									  
									  <p className="requisitos"> ÚNICO REQUISITO: TENER LICENCIA IMPORTADOR</p>
									  
									  <p>Para realizar una cotización envíe un mail a<br/>
									aereo@raiconet.com con los siguientes datos:
									</p>
									
									<ul>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Origen.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Peso (Kg).</li>
										<li><svg
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}}										
										  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										<span className="d-sm-inline-block">Medidas (cm) o Peso Volumétrico <br className="d-none d-sm-block"/>
										(Largo x alto x ancho/5000).</span></li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Valor FOB (USD).</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Descripción del contenido.</li>
									</ul>
									
									<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
									
									<p>Si desea contratar o recibir más información sobre nuestro servicio:</p>
									
									<p className="requisitos"
									style={{ marginBottom: '30px' }}><a href="mailTo:aereo@raiconet.com" target="_blank">CONSULTE AQUÍ</a></p>
									
									
									</div>
									  </motion.section>
									)}
								</AnimatePresence>
							</div>
					</div>
				</div>
				
				<div id="" className={"col-12 col-sm-6 expImg noLimits"} 
							  data-aos="zoom-in"								  
							  data-aos-duration="900">
					<div className={limitsExpand ? 'expanded' : ''}></div>
				</div>
			</div>
		</div>
		<div id="carga" className="raicoContainer mt-30">
			<div className="row">
				<div id="expCol" className="col-12 col-sm-6">
					<div className={"expTxt bgRed "+(cargaExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
								
								<h2>Servicio de Carga Aérea</h2></div>
								
								{/*<p className="text-white">Tomamos su carga aérea desde cualquier lugar del<br/>
								mundo.</p>
								<p className="text-white">Coordinamos con rapidez el despacho de sus<br/>
								embarques accediendo a vuelos directos y diarios.</p>
								
								<p className="text-white">Servicio de transporte Aéreo internacional hasta el <br/>aeropuerto, diseñado para tomar sus cargas en<br/> cualquier lugar del mundo.</p>
								*/}
								
								<p className="text-white"
								style={{paddingRight: '0'}}>
								Servicio de transporte aéreo hasta el aeropuerto diseñado para tomar sus cargas en cualquier lugar del mundo.</p>
								
								{!cargaExpand &&
									<div id="expandCarga" className={"expand text-white "+(cargaExpand ? 'invisible' : '')}
										onClick={() => setCargaExpand(!cargaExpand)}
									>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
		  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
		</svg>
										CONOCER MÁS
									</div>
								}
								<AnimatePresence initial={false}>
									{cargaExpand && (
									  <motion.section
										key="content"
										initial="collapsed"
										animate="open"
										exit="collapsed"
										variants={{
										  open: { opacity: 1, height: "auto" },
										  collapsed: { opacity: 0, height: 0 }
										}}
										transition={{ duration: 1.1, ease: [0.04, 0.62, 0.23, 0.98] }}
									  >
									  <div className="infoExpanded">
									  
									  <p>Contamos con acuerdos exclusivos con los principales operadores, lo que nos permite contar con las mejores tarifas del mercado, y coordinar con rapidez el despacho de sus embarques accediendo a frecuencias de vuelos directos y diarios.</p>
									  
									  <p>Para optimizar el envío de carga consolidada de compras a diversos proveedores, contamos con depósitos en Miami (MIA), Houston (IAH), Madrid (MAD) y Shenzhen (SZX). Todos ellos habilitados para la recepción de carga y realización de manejos especiales, como ser: </p>
									  
									  <ul
									  style={{marginTop: '20px',
									  marginBottom: '30px' }}
									  >
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Re-empaque</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Toma de contenido</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Consolidación</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Control de documentación</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Fraccionamientos para su posterior despacho</li>
									  </ul>
									  
									  <ul className="beneficios"
										style={{marginBottom: '20px'}}>
											<li><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /></li>
										</ul>
									  
									  <ul className="beneficios">
										<li><img src={estrella} alt="" title="" />Adelantamos al despachante toda la información del
										embarque para evitar costos extras de almacenaje.</li>
										<li><img src={estrella} alt="" title="" />Realizamos el desconsolidado expréss, y entregamos la documentación original, el mismo día del arribo del vuelo.</li>
									  </ul>
									  
									  <p className="requisitos"> ÚNICO REQUISITO: TENER LICENCIA IMPORTADOR</p>
									  
									  <p>Para realizar una cotización envíe un mail a<br/>
									aereo@raiconet.com con los siguientes datos:
									</p>
									
									<ul
									style={{marginTop: '30px',
									  marginBottom: '20px' }}
									>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Origen.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Peso (Kg).</li>
										<li><svg
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}}
										xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										<span className="d-sm-inline-block">Medidas (cm) o Peso Volumétrico <br className="d-none d-sm-block"/>
										(Largo x alto x ancho/5000).</span></li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Valor FOB (USD).</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Descripción del contenido.</li>
									</ul>
									
									<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
									
									<p>Si desea contratar o recibir más información sobre nuestro servicio:</p>
									
									<p className="requisitos"
									style={{ marginBottom: '30px' }}><a href="mailTo:aereo@raiconet.com" target="_blank">CONSULTE AQUÍ</a></p>
									
									
									</div>
									  </motion.section>
									)}
								</AnimatePresence>
							</div>
					</div>
				</div>
				
					<div id="" className={"col-12 col-sm-6 expImg carga"} 
								  data-aos="zoom-in"								  
								  data-aos-duration="900">
						<div className={cargaExpand ? 'expanded' : ''}></div>
					</div>
			</div>
		</div>
		<Warehouses />
		<img src={cargaExpandedImg} style={{ display: 'none' }} />
  </Layout>
)
}
export default ImportacionPage
